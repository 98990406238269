import React from 'react';
import OldApp from './App';
import { AppSdkWrapper } from './sdk-app/App';
import { priorAuthLogger } from './logger';

const AppWrapper: React.FC = () => {
  const shouldUseOldApp = (window as any)?.$vim_environment?.service === 'prior-auth';
  priorAuthLogger.info('Init prior auth app', { noPHI: true, shouldUseOldApp });

  return <>{shouldUseOldApp ? <OldApp /> : <AppSdkWrapper />}</>;
};

export default AppWrapper;
