export interface Storage<TMap = Record<string, any>> {
  get: <T extends keyof TMap>(key: T) => Promise<TMap[T]>;
  set: <T extends keyof TMap>(key: T, value: TMap[T]) => Promise<void>;
}

export enum StorageKnownKey {
  DEVICE_ID = 'device_id',
  HOSTNAME = 'hostname',
  USERNAME = 'username',
  SALT = 'salt',
  VIM_TOKENS = 'vimTokens',
  OVERRIDE_ORGANIZATION = 'overrideOrgName',
  OVERRIDE_WIDGETS_CONFIGURATION = 'overrideWidgetsConfiguration',
  MSI_VERSION = 'MSI_VERSION',
  KEY = 'KEY',
  EHR = 'EHR',
  EHR_URL = 'EHR_URL',
  OVERRIDE_ENV_CONFIG = 'overrideEnvironmentConfig',
  OVERRIDE_ORG_KEY = 'overrideOrgKey',
  OVERRIDE_PROCESS = 'overrideProcess',
  NOTIFICATIONS_DETAILS = 'notificationsDetails',
  VIM_CONNECT_UI = 'vim-connect-ui',
  TECHNICAL_OPTIONS = 'technicalOptions',
  LAST_AUTOMATIC_REFRESH_TIME = 'lastAutomaticRefreshTime',
  OS_VERSION = 'os_version',
  USE_OS_SIMULATOR = 'osSimulator',
  WORKFLOW_INTEGRATION_LOGGER_URL = 'WORKFLOW_INTEGRATION_LOGGER_URL',
  OS_SIMULATOR_DATA = 'OS_SIMULATOR_DATA',
  ORGANIZATION_KEY = 'organizationKey',
  WIN32DEPRECATION_DEBUG = 'win32Deprecation_debugMode',
  WIN32DETECTOR_STOP = 'win32Deprecation_stop',
}
