import React, { useEffect, useState } from 'react';
import { loadSdk } from 'vim-os-js-browser';
import { SDK } from 'vim-os-js-browser/types';
import { VimOSContext } from './vimOsContext';
import { priorAuthLogger } from '../logger';

export const AppSdkWrapper: React.FC = () => {
  const [vimOS, setVimOS] = useState<SDK | undefined>(undefined);
  useEffect(() => {
    (async () => {
      try {
        const sdk = await loadSdk();
        setVimOS(sdk);
      } catch (error) {
        priorAuthLogger.error('Failed to load SDK', {
          message: (error as any).message,
          noPHI: true,
        });
      }
    })();
  }, []);

  if (!vimOS) {
    return <div>Loading VimSDK...</div>;
  }

  return <VimOSContext.Provider value={vimOS}>SDK loaded</VimOSContext.Provider>;
};
