import { FeatureFlagProvider } from '@getvim/feature-flags-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { featureFlagsClientOrderOptimization } from './services/featureFlags';
import AppWrapper from './AppWrapper';

ReactDOM.render(
  <React.StrictMode>
    <Router basename={import.meta.env.BASE_URL}>
      <FeatureFlagProvider featureFlagsClient={featureFlagsClientOrderOptimization}>
        <AppWrapper />
      </FeatureFlagProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);
